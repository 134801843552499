export const socialsData = {
  github: "https://github.com/mrpeppermintkg",
  facebook: "https://www.facebook.com/kartikeya.garg.07",
  linkedIn: "https://www.linkedin.com/in/kartikeyagarg/",
  instagram: "https://www.instagram.com/mr.peppermint.kg/",
  // codepen: "https://codepen.io/programing-school",
  twitter: "https://twitter.com/mrpeppermint_kg",
  // reddit: "https://www.reddit.com/user/",
  // blogger: "https://programing-school-blog.blogspot.com/",
  medium: "https://medium.com/@mr.peppermint.kg",
  // stackOverflow: "https://stackoverflow.com/users/15508803/Kartikeya- Garg",
  // gitlab: "https://gitlab.com/",
  // youtube: "https://www.youtube.com/channel/UC1YTVmV31RZV2oie1kKpJkw",
};