export const achievementData = {
  bio: "Some of the Achievements ",
  achievements: [
    {
      id: 1,
      title: "Microsoft Azure Fundamentals",
      details: "Azure services, Azure workloads, security and privacy in Azure, as well as Azure pricing and support.",
      date: "28 January, 2023",
      field: "Cloud/DevOps",
      link:'https://www.credly.com/badges/c17ef8f2-3366-45ad-8954-2138c91eeb41/linked_in_profile',
      image:
        "https://images.credly.com/size/340x340/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png",
    },
    {
      id: 2,
      title: "Oracle Cloud Infrastructure Foundations 2021 Associate",
      details: "It involves in selling or procuring cloud solutions to validate their foundational-level knowledge around core OCI services.",
      date: "11 December, 2021",
      field: "Cloud/DevOps",
      link: 'https://catalog-education.oracle.com/pls/certview/sharebadge?id=D588DE19F2D0ACDBE0548E9A7E06D674147C9C89C61B9D6AEC4961B25B574729',
      image:
        "https://brm-workforce.oracle.com/pdf/certview/images/102_Oracle_Cloud_Infrastructure_Foundations_Associate.png",
    },
  ],
};


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/