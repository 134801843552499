export const aboutData = {
    title: "Who I am",
    description1: "My name is Kartikeya Garg. I'm a DevOps Engineer,India.",
    /*description2: "I work as a DevOps Engineer for an organization during the day, and in the evenings, I devote my time to building my own projects while also enhancing my skills.At present, I am experimenting with new DevOps tools like . I have a great passion for learning and discovering new areas.",*/
    description2: " I am working as a DevOps Engineer for an organisation during the day and in the evenings , I devote my time to build my own projects by working on different DevOps Tools",
    description3:   
      
    `Work Summary:

    ●Worked on AWS services: VPC, SG, ELB, IAM, RDS, S3, SNS, SES, WorkMail, EKS, ECS.
    ●Implemented CI/CD with Jenkins, Github Action, Gitlab CI and AWS CodePipeline.
    ●Managed EC2 instances, installed/configured Nginx, Apache.
    ●Worked on some services of Azure and GCP. 
    ●Deployed various projects of React/Vue/Angular/Nodejs/Wordpress/PHP/Laravel/Drupal on different cloud platforms.
    ●Automated EBS data backups to S3, created EBS snapshots.
    ●Configured RDS for MySQL databases, applied S3 management policies.
    ●Created/deployed 3-tier architecture for multiple projects.
    ●Handled AWS troubleshooting, IAM user/role/policy management.
    ●Prepared and maintained AWS billing`,

    image: 2
}
