import one from '../assets/svg/projects/django.png';
import two from '../assets/svg/projects/Kepler.png';
import three from '../assets/svg/projects/three.png';
import four from '../assets/svg/projects/four.png';

export const projectsData = [
  {
    id: 1,
    projectName: "Stock Stream",
    projectDesc: "Information website for stocks and investments",
    tags: ["Django","AWS","Nginx"],
    demo: "#",
    image: one,
  },
  {
    id: 2,
    projectName: "Cathodic Spark",
    projectDesc: " Designed to explore large-scale geolocation data sets in a high-performance and data-agnostic manner. It uses Kepler.gl as a react component",
    tags: ["React,Python","AWS","PostgreSQL","Docker-Compose","Gitlab CI/CD","Nginx"],
    demo: "#",
    image: two,
  },
  {
    id: 3,
    projectName: "Liberty Programs",
    projectDesc: "Your one-stop destination for Microsoft Office and Windows Operating Systems. Whether you're an individual, running a business, or affiliated with a government or educational institution, we've got your digital needs covered",
    tags: ["React","Nodejs","AWS","MongoDB","Nginx"],
    demo: "#",
    image: three,
  },
  {
    id: 4,
    projectName: "Premier Car Parks",
    projectDesc: " Our parking area is well-lit, secured by protective barriers, and features an effective video surveillance system equipped with automatic intrusion detection",
    tags: ["React","Nodejs","AWS","MongoDB","Nginx"],
    demo: "#",
    image: four,
  }
];
