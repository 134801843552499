export const blogData = [
  {
    id: 1,
    title:
      "A Roadmap for Enterprise DevOps",
    description: "Overview of DevOps Foundation & Implementation of DevOps",
    date: "January 13, 2022",
    image:
      "https://miro.medium.com/v2/resize:fit:720/format:webp/1*51cbG5O-BPjsjHdaIrIB7g.png",
    url: "https://medium.com/@mr.peppermint.kg/a-roadmap-for-enterprise-devops-f068f3bf41f2",
  },
  {
    id: 2,
    title:
      "Application Monitoring for MultiCloud Success",
    description: "Overview of Application Monitoring ",
    date: "April 13 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fill:112:112/1*XS28wF4OVPvBUMRYmFhURA.png",
    url: "https://medium.com/@mr.peppermint.kg/application-monitoring-for-multicloud-success-9c336a6d946e",
  },
  {
    id: 3,
    title:
      "IAC, Tools, and Comparison",
    description: "Overview of IAC tools as well as comparison of differnet tools.",
    date: "April 17 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*4I3dnmMhRAbbdD5SDzx6bQ.jpeg",
    url: "https://medium.com/@mr.peppermint.kg/iac-tools-and-comparison-70c60cd2191d",
  },
  {
    id: 4,
    title:
      "Modernizing your build pipelines",
    description: "Overview of IAC tools as well as comparison of differnet tools.",
    date: "April 19 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*hFD_i7e_DhQlg0VyGr8w2Q.png",
    url: "https://medium.com/@mr.peppermint.kg/modernizing-your-build-pipelines-eede4542b2c1",
  },
  {
    id: 5,
    title:
      "Internal Developer Platform",
    description: "A Self-Service Solution",
    date: "April 21 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1394/format:webp/1*2zrnTiw7BUBQ8Cyjc3rF2A.png",
    url: "https://medium.com/@mr.peppermint.kg/idp-a-self-service-solution-81ab3fa6029b",
  },
  {
    id: 6,
    title:
      "The Ultimate Strategy for Hybrid and Multi-Cloud",
    description: "Expansion & Cost Control of Multi-Cloud and Hybrid Infrastructure",
    date: "April 25 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*AOB_J0qhO3PIOMqXGj00zg.png",
    url: "https://medium.com/@mr.peppermint.kg/the-ultimate-strategy-for-hybrid-and-multi-cloud-85f58c324268",
  },

  {
    id: 7,
    title:
      "Deep Dive Into Cloud-Native Observability",
    description: "Fundamental open-source, cloud-native observability tools?",
    date: "May 5 , 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*88-pX4uTrXJRJt8PfCcssg.png",
    url: "https://medium.com/@mr.peppermint.kg/deep-dive-into-cloud-native-observability-1fcdf9ca68b3",
  },

    {
    id: 8,
    title:
      "Everything that you need to know about Continuous Delivery",
    description: "Why Is It Beneficial? What Exactly Is Continuous Delivery?",
    date: "May 12 , 2023",
    image:
      "	https://miro.medium.com/v2/resize:fit:1400/format:webp/1*dwGcvWmLZRmh7IMOrdgVTg.png",
    url: "https://medium.com/@mr.peppermint.kg/everything-that-you-need-to-know-about-continuous-delivery-a5a5c7c37fce",
  },


  // {
  //   id: 2,
  //   title:
  //     "Transparent Taskbar – How to Make a Task Bar Transparent in Windows 10 PC",
  //   description: "Make Your Taskbar Transparent in Windows 10 PC",
  //   date: "Jan 26, 2022",
  //   image: "https://media.graphcms.com/SmNVj0YPRjGf1Lsp6W9o",
  //   url: "https://programing-school-blog.vercel.app/post/transparent-taskbar",
  // },
  // {
  //   id: 3,
  //   title: "Navbar With CSS Flexbox",
  //   description: "Make your own Navbar With CSS Flexbox",
  //   date: "Aug 14, 2020",
  //   image:
  //     "https://programing-school.hashnode.dev/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Funsplash%2FMI9-PY5cyNs%2Fupload%2Fv1642002894210%2FpbXRt9A_L.jpeg%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=1920&q=75",
  //   url: "https://programing-school.hashnode.dev/navbar-with-flexbox-ckybri1no0ensf8s11xj327he",
  // },
  // {
  //   id: 4,
  //   title: "Learn Git & GitHub",
  //   description: "Learn Git and GitHub from experts 😎",
  //   date: "Jan 29, 2022",
  //   image:
  //     "https://programing-school.hashnode.dev/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1643451400712%2FdHEknLTho.png%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=1920&q=75",
  //   url: "https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html",
  // },
];




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/