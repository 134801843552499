import resume from '../assets/pdf/RESUME.pdf';
/*import logo from '../assets/Logo.jpg';*/
import logo from '../assets/kg.png';

export const headerData = {
    name: 'Kartikeya Garg',
    title: "DevOps Engineer",
    desciption:"Hey there!!, I am a DevOps Engineer having 2+ years of experience.\nMy proficiency in various DevOps tools like Jenkins, Terraform, Docker, Kubernetes, and AWS has allowed me to design and implement CI/CD pipelines, deploy and manage cloud infrastructure, automate the build and deployment process, and monitor application performance.",
    image: logo,
    resumePdf: resume
}
