export const experienceData = [
    {
        id: 1,
        company: 'Xenonstack Private Limited,Mohali',
        jobtitle: 'SRE/DevOps Engineer',
        startYear: 'January 2021',
        endYear: 'February 2022'
    },
    {
        id: 2,
        company: 'Expandimo Private Limited,Mohali',
        jobtitle: 'DevOps Engineer',
        startYear: 'May 2022',
        endYear: 'May 2023'
    },
    {
        id: 3,
        company: 'Confidential',
        jobtitle: 'DevOps Engineer',
        startYear: 'May 2023',
        endYear: 'Present'
    },

]
